import React from 'react';
import { Box } from '@chakra-ui/core';

const parseSpotifyString = url => {
  if (typeof window !== 'undefined') {
    const parser = window.document.createElement('a');
    parser.href = url;
    return `https://open.spotify.com/embed${parser.pathname}`;
  }
};

const SpotifyEmbed = ({ trackUrl }) => {
  return (
    <Box margin="10px 0" width="full">
      <iframe
        title={`spotify-embed-${trackUrl}`}
        src={parseSpotifyString(trackUrl)}
        style={{ border: 0, width: '100%', height: '80px' }}
        allowfullscreen
        allow="encrypted-media"
      ></iframe>
    </Box>
  );
};

export default SpotifyEmbed;
