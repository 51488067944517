import React from 'react';
import { graphql } from 'gatsby';
import { Text, Box, Flex } from '@chakra-ui/core';
import EmbedContainer from 'react-oembed-container';

import SpotifyEmbed from '../../components/SpotifyEmbed';
import Layout from '../../components/Layout';
import HeaderImage from '../../components/HeaderImage';
import './style.css';

const StaticPage = ({ data }) => {
  const songOne = data.wordpressPage.acf.song_1;
  const songTwo = data.wordpressPage.acf.song_2;
  const songThree = data.wordpressPage.acf.song_3;
  const songFour = data.wordpressPage.acf.song_4;
  const songFive = data.wordpressPage.acf.song_5;
  const songSix = data.wordpressPage.acf.song_6;

  return (
    <Layout>
      <Box>
        <HeaderImage
          imageData={
            data.wordpressPage.acf.header_image.localFile.childImageSharp.fluid
          }
          backgroundPosition="center center"
          className="dimmed"
        >
          <Text
            textAlign="center"
            fontSize="6xl"
            font-weight="400"
            color="white"
          >
            {data.wordpressPage.title}
          </Text>
        </HeaderImage>
        <EmbedContainer markup={data.wordpressPage.content}>
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            width="100%"
            mb={6}
            dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
          />
          <Flex flexDirection="column">
            {songOne && <SpotifyEmbed trackUrl={songOne} />}
            {songTwo && <SpotifyEmbed trackUrl={songTwo} />}
            {songThree && <SpotifyEmbed trackUrl={songThree} />}
            {songFour && <SpotifyEmbed trackUrl={songFour} />}
            {songFive && <SpotifyEmbed trackUrl={songFive} />}
            {songSix && <SpotifyEmbed trackUrl={songSix} />}
          </Flex>
        </EmbedContainer>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query PageQuery($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      content
      title
      template
      slug
      acf {
        header_image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        song_1
        song_2
        song_3
        song_4
        song_5
        song_6
        song_7
      }
    }
  }
`;

export default StaticPage;
